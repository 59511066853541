#deleteProjectsModal .buttonsContainer {
    display: flex;
    flex-flow: row-reverse;
    padding-bottom: 0;
}

#deleteProjectsModal .buttonsContainer button {
    width: 102px;
}

#delete_projects_ok_button {
    background-color: red;
}

.deleteProjectListContainer {
    height: 116px;
    overflow-y: auto;
}

.viewer {
    background-color: white;
    width: 100%;
    position: relative;
    border-style: solid;
    border-color: #D9D9D9;
    height: calc(100vh - 56px);
}

.modelContainer {
    width: calc(100% - 296px);
}

.modelContainer-mobile {
    width: calc(100%)
}
.parametersContainer {
    display: flex;
    flex-flow: column;
    width: 300px;
    background-color: white;
}

.modelContainer-mobile .parametersContainer {
    position: absolute;
    top: 56px;
    left: 0;
    z-index: 5000;
    overflow-y: auto;
    height: calc(100vh - 56px);
    padding-bottom: 10px;
    width: 100%;
}

.pencilContainer {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.parameters {
    overflow: auto;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    flex: auto;
}

.parametersSeparator {
    margin-top: 8px;
    margin-bottom: 0;
    width: calc(100% - 16px);
}

.parameter {
    padding-bottom: 16px;
    font-size: 12px;
    font-family: ArtifaktElement, sans-serif;
    font-weight: 300;
    color: #3c3c3c;
    line-height: 18px;
}

.parameter.checkbox {
    padding-bottom: 20px;
    height: 20px;
    display: flex;
    flex-flow: row;
}

.parameter.checkboxtext {
    padding-left: 12px;
    font-size: 14px;
    line-height: 16px;
}

.stage-color-box {
    float: left;
    width: 16px;
    height: 16px;
    margin-right: 3px;
}
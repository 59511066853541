.tabsContainer {
    display: flex;
    flex-flow: column;
    height: 100%;
    padding-top: 29px;
    overflow: hidden;
}

.tabContent {
    border-style: solid;
    border-width: 8px;
    border-color: #D9D9D9;
}

.tabContent#model,
.tabContent#drawing
{
    border-style: none;
}

.inARow {
    display: flex;
    flex-direction: row !important;
}

/* set style for an element made by React component */
.fullheight__content {
    overflow: hidden;
}

.mobile-visualization-control-active svg path {
    fill: #6c757d;
}

.mobile-visualization-control-inactive svg path {
    fill: lightgray;
}

.mobile-visualization-control-button-active button {
    border-style: solid!important;
    outline: 5px auto -webkit-focus-ring-color;
}

.mobile-visualization-control-button-inactive button {
    border-style: none!important;
    outline: none;
}

.mobile-visualization polygon {
    visibility: hidden;
}
.react-calendar__month-view {
    line-height: 4px;
}

.parameter-buttonsContainer {
    display: flex;
    padding-bottom: 8px;
    padding-top: 8px;
}

.parameter-buttonsContainer button {
    padding-left: 2px;
    padding-right: 2px;
}
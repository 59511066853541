.passwordbox input {
    -webkit-text-security: disc;
    -text-security: disc;
    font-family: text-security-disc;
}

.buttonsContainer {
    display: flex;
    flex-flow: row-reverse;
    padding-bottom: 0;
}

.buttonsContainer button {
    width: 102px;
}

.loginform article {
    height: 338px;
}

.invalid-login {
    color: red !important;
    font-size: 10px !important;
    text-align: right !important;
}

.loginMainContainer {
    display: flex;
    flex-flow: column;
    height: 100%;
    padding-top: 29px;
    overflow: hidden;
}

.loginMainContainer header button {
    display: none;
}
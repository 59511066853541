.no-data {
    font-family: ArtifaktElement, sans-serif;
    padding: 50px;
    color: #3C3C3C;
}

.no-data .title {
    font-size: 28px;
    margin: 25px;
    margin-top: 50px;
    text-align: center;
}

/* trick to change SVG color. https://stackoverflow.com/a/49627345 */
.no-data .title svg path {
    fill: #FAA21B;
}

.no-data .image {
    /* background-image: url('/no-data.svg'); don't know why this shit happens, but stupid react script build fails here */
    background-position: center;
    background-repeat: no-repeat;
    height: 150px;
}

.no-data .details {
    /* font-family: ArtifaktElement-Medium, sans-serif; */
    font-weight: 600;
    margin: 25px;
    padding-left: 150px;
}

html, body {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;
}

.fullheight {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.anonymous-pane {
  display: flex;
  flex-direction: row !important;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

/* whole page scroll bars */
::-webkit-scrollbar-thumb:hover {
    background-color: #808080;
    -ms-opacity: 0.5;
    opacity: 0.5
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #808080;
    -ms-opacity: 0.2;
    opacity: 0.2;
}

::-webkit-scrollbar {
  width: 5px;
}
#projects-list .tabContainer {
    background-color: white;
    font-family: ArtifaktElement, sans-serif;
}

#projects-list .hidden {
    display: none;
}

#projects-list .actionButtonContainer.hidden {
    padding: 0;
}

#projects-list .actionButtonContainer {
    display: flex;
    flex-flow: row;
    padding: 20px 0 10px 0;
    margin-left: 76px;
    vertical-align: middle;
}

#projects-list .actionButtonContainer button {
    /* following are to override IconButton strange behavior */
    outline: none;
    border-color: transparent;
    box-shadow: none;
}

#projects-list .actionButtonContainer .verticalSpacer {
    height: 40px;
    width: 1px;
    border-left: gainsboro 1px solid;
    margin: 0 12px 0 12px;
}

#project-data .fileBrowseContainer {
    display: flex;
}

#project-data .fileBrowseContainer button {
    /* following are to override IconButton strange behavior */
    outline: none;
    border-color: transparent;
    box-shadow: none;
}

#project-data .stretch {
    width: -webkit-fill-available;
}

#project-data .browseButton {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
}

#project-data .browseButton label {
    margin: 0;
    height: 36px; /* to match inner content (the button) exactly */
}

#modelFileInput {
    display: none;
}

#project-data .buttonsContainer {
    display: flex;
    flex-flow: row-reverse;
    padding-bottom: 0;
}

#project-data textarea {
    height: 100px !important;
}

#project-data .buttonsContainer button {
    width: 102px;
}

.upload-fail-message {
    color: red !important;
    font-size: 10px !important;
    text-align: right !important;
    padding-right: 36px !important;
}

#project-data .creation-failed-message {
    color: red !important;
    font-size: 10px !important;
    text-align: right !important;
}

.checkboxfield {
    position: relative;
}

.stretch .checkboxtext {
    position: absolute;
    top: 0;
    left: 25px;
    font-size: 14px;
    font-family: ArtifaktElement,sans-serif;
}
span.user {
    font-size: 11px;
    margin-left: 12px;
  }
  
  .avatar-custom-style {
    margin-left: auto !important;
    margin-right: 12px !important;
  }
  
  .avatar-custom-style img {
    background-color: white;
  }
  
  span.username, span.hyperlink {
    width: 70px;
    height: 20px;
    font-size: 14px;
    font-family: ArtifaktElement, sans-serif;
    line-height: 20px;
    margin: 12px;
  }
  
  span.username {
    color: rgb(60, 60, 60);
    font-weight: 700;
  }
  
  div.auth-button {
    width: 244px;
    height: 36px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgb(128, 128, 128);
    border-radius: 2px;
    margin: 12px;
    cursor: pointer;
    text-align: center;
  }
  
  span.auth-button-text {
      line-height: 36px;
  }
  
  #hiddenLogoutFrame {
    visibility: hidden;
    display: block;
    height: 0;
    width: 0;
  }
  